import en from './assets/localization/en.json'
import ar from './assets/localization/ar.json'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

export default  new VueI18n({
    locale: localStorage.getItem('lang') || 'ar',
    messages: {
        ar,
        en
    }
})