<template>
  <footer class="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
        <span>{{$t('copyright')}}</span>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
</style>