<template>
  <div>
      <math-field style="direction: ltr; width: 100%;"
        id="formula"
        ></math-field
      >
  </div>  
</template>

<script>


export default {
  props: {},
  data () { 
    return {
        }
      },
};
</script>

<style>
/* Add your styles here */
</style>
