<template>
  <div  class="loading">
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading {
  color:#2196f3;
  text-align: center;
  width: 100%;
}
</style>