<template>
    <div class="row rtl">
        <div class="col-md-12">
            <h4 class="c-grey-900">{{subject}}</h4>
            <h4 class="c-grey-900">{{new Date().toUTCString()}}</h4>
            <table
            id="dataTable"
            class="table"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  الدرس
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 200px"
                  aria-label="Position: activate to sort column ascending"
                >
                  المدة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 200px"
                  aria-label="Position: activate to sort column ascending"
                >
                  التصحيح
                </th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in reversedVideos" :key="item.id">
              <tr role="row" class="odd">
                <td>
                  {{index +1 }}
                </td>
                <td>
                  {{item.name }}
                </td>
                <td>
                  {{item.duration }}
                </td>
                <td>
                    <input type="checkbox"/>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
    </div>

  </template>
  
  <script>

export default {
  props: {
    videos: {
      type: Array,
      required: true
    },
    subject: {
      type: String,
      required: false
    }
  },
  computed: {
    reversedVideos() {
      return [...this.videos].reverse(); // Return a reversed copy of the array
    }
  }
}

  </script>
  
  <style>
  
  </style>
  