<template>
  <div id="loader">
    <div class="spinner-border primary" role="status"></div>
  </div>
</template>

<script>
export default {

};

window.addEventListener('load', () => {
        const loader = document.getElementById('loader');
        loader.classList.add('fadeOut');
  });
</script>

<style scoped>
#loader {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 90000;
}

#loader.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.spinner-border {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  color:#2196f3;
}
</style>