<template>
  <div class="sidebar">
    <div class="sidebar-inner" style="border-right: 1px #ddd solid">
      <!-- ### $Sidebar Header ### -->
      <div class="sidebar-logo" style="border-bottom: white">
        <div class="peers ai-c fxw-nw" style="padding: 20 20px">
          <div class="peer peer-greed">
            <a
              class="sidebar-link td-n"
              href="https://colorlib.com/polygon/adminator/index.html"
              ><div class="peers ai-c fxw-nw">
                <div class="peer">
                  <div class="logo">
                    <img src="@/assets/static/images/logo/logo.png" style="margin-top:20px;" alt="" height="25"/>
                  </div>
                </div>
                <div class="peer peer-greed">
                  <h5 class="lh-1 mB-0 logo-text">لوحة تحكم يلا إمتحان</h5>
                </div>
              </div></a
            >
          </div>
          <div class="peer">
            <div class="mobile-toggle sidebar-toggle">
              <a href="" class="td-n">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- ### $Sidebar Menu ### -->
      <ul class="sidebar-menu scrollable pos-r">
        <li v-if="isSupervisor()" class="nav-item mT-30 actived">
          <router-link class="sidebar-link" to="/reports">
            <span class="icon-holder">
              <i class="c-blue-500 ti-pie-chart"></i>
            </span>
            <span class="title">التقارير</span>
          </router-link>
        </li>
        <li v-if="isSupervisor()" class="nav-item">
          <router-link class="sidebar-link" to="/students">
            <span class="icon-holder">
              <i class="c-blue-500 ti-id-badge"></i>
            </span>
            <span class="title">{{$t('students')}}</span>
          </router-link>
        </li>
        <li v-if="isSupervisor()" class="nav-item">
          <router-link class="sidebar-link" to="/employees">
            <span class="icon-holder">
              <i class="c-blue-500 ti-user"></i>
            </span>
            <span class="title">المشرفين</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/levels">
            <span class="icon-holder">
              <i class="c-blue-500 ti-agenda"></i>
            </span>
            <span class="title">المراحل</span>
          </router-link>
        </li>
        <li v-if="isSupervisor()" class="nav-item">
          <router-link class="sidebar-link" to="/questionsComplaints">
            <span class="icon-holder">
              <i class="c-blue-500 ti-receipt"></i>
            </span>
            <span class="title">بلاغات الأسئلة</span>
          </router-link>
        </li>
        <li v-if="isSupervisor()" class="nav-item dropdown">
          <a class="sidebar-link" href="ui.html">
            <span class="icon-holder">
              <i class="c-blue-500 ti-stats-up"></i>
            </span>
            <span class="title">الإيرادات و المنصرفات</span>
          </a>
        </li>
        <li v-if="isSupervisor()" class="nav-item dropdown">
          <a class="sidebar-link" href="javascript:void(0);">
            <span class="icon-holder">
              <i class="c-blue-500 ti-money "></i>
            </span>
            <span class="title">بنكك</span>
          </a>
        </li>
        <li v-if="isSupervisor()" class="nav-item dropdown">
          <router-link class="sidebar-link" to="/complaints">
            <span class="icon-holder">
              <i class="c-blue-500 ti-comment-alt"></i>
            </span>
            <span class="title">المقترحات و الشكاوي</span>
          </router-link>
        </li>
        <li v-if="isSupervisor()" class="nav-item dropdown">
          <a class="sidebar-link" href="javascript:void(0);">
            <span class="icon-holder">
              <i class="c-blue-500 ti-files"></i>
            </span>
            <span class="title">الإشتراكات</span>
          </a>
        </li>
        <li v-if="isSupervisor()" class="nav-item dropdown">
          <a class="sidebar-link" href="javascript:void(0);">
            <span class="icon-holder">
              <i class="c-blue-500 ti-power-off"></i>
            </span>
            <span class="title">تسجيل خروج</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { method } from 'lodash';

export default {
methods: {
  isSupervisor() {
    return sessionStorage.getItem('userTypeId') != 1;
  }
}
};
</script>

<style>
</style>