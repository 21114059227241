import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../components/Redirect.vue')
  },
  {
    path: '/',
    component: () => import('../views/home/Home.vue'),
    children: [
      {
        path: 'students',
        name: 'students',
        component: () => import('../views/students/Students.vue'),
      },
      {
        path: 'employees',
        name: 'employees',
        component: () => import('../views/employees/Employees.vue'),
      },
      {
        path: 'questionsComplaints',
        name: 'questionsComplaints',
        component: () => import('../views/questionsComplaints/QuestionsComplaints.vue'),
      },
      {
        path: 'complaints',
        name: 'complaints',
        component: () => import('../views/complaints/Complaints.vue'),
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('../views/reports/Reports.vue'),
      },
      {
        path: 'classes',
        name: 'classes',
        props: (route) => ({
          id: route.query.id,
          level: route.query.level
        }),
        component: () => import('../views/levels/classes/Classes.vue'),
      },
      {
        path: 'subjects',
        name: 'subjects',
        props: (route) => ({
          id: route.query.id,
          classs: route.query.classs
        }),
        component: () => import('../views/levels/classes/subjects/Subjects.vue'),
      },
      {
        path: 'exams',
        name: 'exams',
        props: (route) => ({
          id: route.query.id,
          subject: route.query.subject
        }),
        component: () => import('../views/levels/classes/subjects/Exams.vue'),
      },
      {
        path: 'lessons',
        name: 'lessons',
        props: (route) => ({
          id: route.query.id,
          chapter: route.query.chapter
        }),
        component: () => import('../views/levels/classes/subjects/chapters/lessons/Lessons.vue'),
      },
      {
        path: 'recapSubject',
        name: 'recapSubject',
        props: (route) => ({
          id: route.query.id,
          subject: route.query.subject
        }),
        component: () => import('../views/levels/classes/subjects/RecapSubject.vue'),
      },
      {
        path: 'videos',
        name: 'videos',
        props: (route) => ({
          id: route.query.id,
          subject: route.query.subject
        }),
        component: () => import('../views/levels/classes/subjects/Videos.vue'),
      },
      {
        path: 'questions',
        name: 'questions',
        props: (route) => ({
          id: route.query.id,
          lesson: route.query.lesson
        }),
        component: () => import('../views/levels/classes/subjects/chapters/lessons/questions/Questions.vue'),
      },
      {
        path: 'chapters',
        name: 'chapters',
        props: (route) => ({
          id: route.query.id,
          subject: route.query.classs
        }),
        component: () => import('../views/levels/classes/subjects/chapters/Chapters.vue'),
      },
      {
        path: 'levels',
        name: 'levels',
        component: () => import('../views/levels/Levels.vue'),
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../components/404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../components/500.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== '404' && to.name !== '500') {
    if (sessionStorage.getItem('userTypeId') === null) {
      if (sessionStorage.getItem('token') === null) {
        if (localStorage.getItem('phone') === null) {
          if(to.name === "login") {
            next();
          }
           else  next({ name: 'login' })
        }
        else{
          if(to.name === "redirect") {
            next();
          }
           else  next({ name: 'redirect' })
        }
      } else {
        if(to.name === "selectBranch") {
          next();
        }
         else  next()
      }
    }
      else if (to.name === "redirect" | to.name === "login" | to.name === "selectBranch") {
        next({ name: 'levels' })
      }
      else next()
    }
    else next()
  })


export default router
