import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueResource from 'vue-resource'
import * as $ from 'jquery'
import Loader from './components/Loader.vue'
import Loading from './components/Loading.vue'
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header.vue'
import VueSlideoutPanel from 'vue2-slideout-panel';
import Footer from './components/Footer.vue'
import Formula from './components/Formula.vue'
import VideosPrintModel from './components/VideosPrintModel.vue'
import Lang from './components/Lang.vue'
import i18n from './localization'
import Pagination from 'vue-pagination-2';
import SuiVue from 'semantic-ui-vue';
import * as MathLive from "mathlive";
import VueMathjax from 'vue-mathjax'

const lang = localStorage.getItem('lang') || 'ar'
document.documentElement.lang = lang;
window.$ = $
Vue.use(VueResource)
Vue.use(SuiVue);
Vue.use(VueSlideoutPanel)
Vue.use(Toast)
Vue.component('loader',Loader)
Vue.component('pagination', Pagination);
Vue.component('loading',Loading)
Vue.component('PrintModel',Loading)
Vue.component('videosPrintModel',VideosPrintModel)
Vue.component('sidebar',Sidebar)
Vue.component('app-header',Header)
Vue.component('app-footer',Footer)
Vue.component('formula',Formula)
Vue.component('lang',Lang)
Vue.use(MathLive);
Vue.use(VueMathjax)

Vue.config.productionTip = false

import '@/assets/scripts/index.js';

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
